import React, { useState, useEffect, useContext } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import SourceEmitter from 'libs/emitter';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';
import './signup.scss';
import Form from './form';

// INITIALIZATIONS
let Subscription = null;
let SubscriptionFormSubmit = null;

const SignUp = props => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.patient;
  // const metaData = appConfigs?.metaData?.patient;

  useEffect(() => {
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });

    goToAnchor('top');
    SubscriptionFormSubmit = SourceEmitter.addListener(
      'FormSubmitted',
      data => {
        if (data) {
          setFormSubmitted(true);
          goToAnchor('top');
        }
      },
    );

    return () => {
      Subscription && Subscription.remove();
      SubscriptionFormSubmit && SubscriptionFormSubmit.remove();
    };
  }, []);

  const pageTitle = 'NASCOBAL® | Patient Sign up';
  const pageDescription = 'Sign up for updates and resources';

  const renderPage = () => {
    return (
      <div id="nascobal-patient-signup">
        <ContentBlock id="cblock-vitaminb12">
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <ColorBlock white>
                  {!formSubmitted ? (
                    <div>
                      <div className="signup-form-container">
                        <h2>
                          NASCOBAL<sup>&reg;</sup> Consumer Sign-up Form
                        </h2>
                        <p className="strong">
                          Thanks for your interest in NASCOBAL<sup>&reg;</sup>.
                        </p>
                        <p>
                          Register to receive tools, resources, and valuable
                          information.
                        </p>
                        <p>
                          Please complete the form below to help us determine
                          the best information for you going forward. You can
                          unsubscribe at any time.
                        </p>
                        <p style={{ paddingTop: 15, fontWeight: 'bold' }}>
                          All fields are required unless marked optional.
                        </p>
                        <Form />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h2>THANK YOU</h2>
                      <p>
                        Thank you for signing up to learn more. You will be
                        hearing from us shortly.
                      </p>
                    </div>
                  )}
                </ColorBlock>
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
        {/* Required for ISI Anchor Link */}
        <ScrollableAnchor id="isi_anchor">
          <div className="page-page-offset" />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="patient-signup">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default SignUp;
